.wrapper {
  padding-top: 42px;
  position: relative;
}

.envelopeWrapper {
  width: 65px;
  height: 65px;
  position: absolute;
  background-color: #00fca3;
  border-radius: 100px;
  z-index: 4;
  top: 40px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardWrapper {
  padding: 55px 22px 27px 22px;
  border-radius: 22px;
  background-color: #ffffff;
  width: 600px;

  @media (max-width: 650px) {
    width: 100%;
  }
}

.titleWrapper,
.descWrapper {
  display: flex;
  justify-content: center;
}

.cardTitle {
  font: 25px/33px var(--visby-semiBold);
  color: hsla(244, 31%, 20%, 1);
  padding: 0 0 14px 0;
  text-align: center;

  @media (max-width: 480px) {
    font-size: 19px;
  }
}

.cardDesc,
.strong {
  font: 17px/28px var(--visby);
  overflow-wrap: break-word;
  color: hsla(244, 31%, 20%, 1);
  text-align: center;
}

.envelopeIcon {
  position: relative;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 36px;
  height: 36px;
  path {
    fill: #252342;
  }
}

.quiestonWrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  cursor: pointer;
}

.emailNotReceived {
  font: 13px var(--visby-semiBold);
  color: #252342;
}

.actionWrapper {
  display: flex;
  align-items: center;
  margin-top: 24px;
  flex-direction: column;
  gap: 24px;
  justify-content: center;
}

.linkWrapper {
  display: flex;
  gap: 24px;
  @media (max-width: 660px) {
    justify-self: center;
  }
}

.buttonWrapper {
  width: 220px;
  display: flex;
  justify-self: center;
  justify-content: center;
}

.strong {
  font-family: var(--visby-bold);
}

.close {
  position: absolute;
  top: 70px;
  right: 32px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
