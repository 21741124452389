.container {
  margin-top: 24px;
}

.title {
  font: 56px var(--visby-medium);
  color: #ffffff;
  @media (max-width: 1279px) {
    font: 36px var(--visby-semiBold);
  }
}

.actionWrapper {
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  max-width: 1166px;
  @media (max-width: 1279px) {
    flex-direction: column-reverse;
    gap: 20px;
    align-items: flex-start;
  }
}

.clientIdWrapper,
.deleteWrapper {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.clientIdWrapper {
  &:hover {
    .clientId {
      color: #00fca3;
    }
    .copyIcon {
      path {
        fill: #00fca3;
      }
    }
  }
}

.clientId {
  font: 20px var(--visby-semiBold);
  color: #ffffff;
  text-transform: uppercase;
  margin-right: 6px;
}

.deleteWrapper {
  margin-top: 20px;
  &:hover {
    .deleteDesc {
      color: #ec4339;
    }
    .binIcon {
      path {
        fill: #ec4339;
      }
    }
  }
}

.deleteDesc {
  font: 16px var(--visby-semiBold);
  color: #ffffff;
  margin-left: 6px;
  margin-top: 3px;
}

.binIcon {
  width: 31px;
  height: 31px;
  path {
    fill: #fff;
  }
}

.copyIcon {
  fill: #fff;
  width: 24px;
  height: 24px;
  margin-bottom: 6px;
}

.cardsWrapper {
  padding-top: 24px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 19px;
  row-gap: 20px;
  @media (max-width: 1075px) {
    flex-direction: column;
  }
}

.close {
  position: absolute;
  top: 70px;
  right: 32px;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.signOut {
  margin-bottom: 3px;
  button div {
    font: 16px var(--visby-semiBold);
  }
}
